import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import { countryOptions, stateOptions } from "./customizer/Data";
import qualityCheckReducer from "./qualityCheckReducer/qualityCheckReducer";
import userFeaturesReducer from "./userFeaturesReducer";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  countryOptions: () => countryOptions,
  stateOptions: () => stateOptions,
  qualityChecks: qualityCheckReducer,
  userFeatures: userFeaturesReducer,
});

export default rootReducer;
