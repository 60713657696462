import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";

const S3ImageWithFallback = ({ src, alt, fallback, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleError = () => {
    if (fallback) {
      setImgSrc(fallback);
    } else {
      setImgSrc(null); // This will render the Avatar component
    }
  };

  return (
    <>
      {imgSrc ? (
        <img src={imgSrc} alt={alt} onError={handleError} {...props} />
      ) : (
        <Avatar />
      )}
    </>
  );
};

export default S3ImageWithFallback;

//Usage example
/* <ImageWithFallback 
  src="https://s3.amazonaws.com/your-bucket/your-image.jpg" 
  alt="Description" 
  fallback="/path/to/placeholder-image.jpg" //removed for showng avatar directly
/> */
