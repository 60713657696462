import React from "react";
import { useSelector } from "react-redux";
import { decryptData } from "./components/crypto";

const OnlyFeaturesPermission = ({ requiredFeature, children }) => {
  const decryptDataSet = useSelector((state) => state.userFeatures.data);
  const userFeatures = decryptData(decryptDataSet);
  if (userFeatures.includes(requiredFeature)) {
    return <>{children}</>;
  }
  return null;
};

export default OnlyFeaturesPermission;
