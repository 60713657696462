const initialState = [];

const qualityCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_OR_UPDATE_QUALITY_CHECK":
      // Check if an object with the same key exists
      const existingIndex = state.findIndex(
        (item) => item.key === action.payload.key
      );
      if (existingIndex !== -1) {
        // If it exists, update the object
        return state
          .map((item, index) => {
            if (index === existingIndex) {
              return {
                ...item,
                discrepancy: action.payload.discrepancy,
                text: action.payload.text,
                id_type: action.payload.id_type,
              };
            }
            return item;
          })
          .filter((item) => item.discrepancy.length > 0); // Filter out objects with empty discrepant arrays
      } else {
        // If it doesn't exist, add a new object
        return [...state, action.payload];
      }
    default:
      return state;
  }
};

export default qualityCheckReducer;
