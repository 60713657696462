const keyString = process.env.REACT_APP_SECRET_KEY; // 32-byte key (no base64)
const iv = new Uint8Array([49, 50, 51, 49, 50, 51, 49, 50, 51, 49, 50, 51]); // 12-byte IV

// Function to convert base64 to ArrayBuffer
const base64ToArrayBuffer = (base64) => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

// Function to convert string to ArrayBuffer
const stringToArrayBuffer = (str) => {
  const encoder = new TextEncoder();
  return encoder.encode(str);
};

// Function to convert ArrayBuffer to base64
const arrayBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  let binary = "";
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

// Function to encrypt text using AES-256
export const encrypt = async (data) => {
  const encoder = new TextEncoder();
  const key = await crypto.subtle.importKey(
    "raw",
    stringToArrayBuffer(keyString), // Convert the 32-byte key string to an ArrayBuffer
    { name: "AES-GCM" },
    false,
    ["encrypt"]
  );

  const encryptedData = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
      tagLength: 128,
    },
    key,
    encoder.encode(data)
  );

  return arrayBufferToBase64(encryptedData);
};

// Function to decrypt text using AES-256
export const decrypt = async (encryptedData) => {
  const key = await crypto.subtle.importKey(
    "raw",
    stringToArrayBuffer(keyString), // Use the same 32-byte key for decryption
    { name: "AES-GCM" },
    false,
    ["decrypt"]
  );

  const decryptedData = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv,
      tagLength: 128,
    },
    key,
    base64ToArrayBuffer(encryptedData) // Convert base64 encoded data to ArrayBuffer
  );

  return new TextDecoder().decode(decryptedData);
};
