// baseUrl
export const baseUrl = process.env.REACT_APP_BASE_URL;
const extention = "/web/api/v1/organisation";
const extentionApi = "/api/v1/organisation";

//Features List API

export const getFeaturesDetailsURL = `${baseUrl}${extention}/get_user_access_feature`;

//Dashboard API urls
export const getDashBoaerdAPIDetailsURL = `${baseUrl}${extention}/dashboard`;
//Collection Dashboard API url
export const getDashBoaerdAPICollectionDetailsURL = `${baseUrl}${extention}/collection/dashboard`;
export const getCollectionBcDashboardUrl = `${baseUrl}${extention}/collection/bc_dashboard`;
export const getCollectionStateDashboardUrl = `${baseUrl}${extention}/collection/state_dashboard`;
export const getCollectionBranchDashboardUrl = `${baseUrl}${extention}/collection/branch_dashboard`;
export const getCollectionUserDashboardUrl = `${baseUrl}${extention}/collection/user_dashboard`;
export const getCollectionCenterDashboardUrl = `${baseUrl}${extention}/collection/center_dashboard`;
export const getCollectionGroupDashboardUrl = `${baseUrl}${extention}/collection/group_dashboard`;
export const getCollectionApplicantDashboardUrl = `${baseUrl}${extention}/collection/applicant_dashboard`;

//user profile URL
export const getUserData = `${baseUrl}${extention}/user_profile`;
export const postUserProfileImageDataUrl = `${baseUrl}${extention}/upload_user_image`;
// Leaderboard endpoint
export const getLeaderBoardAPIDetailsURL = `${baseUrl}${extention}/leaderboard`;
export const getLeaderBoardRankingURL = `${baseUrl}${extention}/leaderboard_rank`;

// All employee endpoint
export const createEmployeeUrl = `${baseUrl}${extention}/employees`;
export const getEmployeeDetailsUrl = `${baseUrl}${extention}/employees`;
export const getParticularEmployeeUrl = (employeeId) =>
  `${baseUrl}${extention}/employee/${employeeId}`;
export const employeePasswordUrl = (employeeId) =>
  `${baseUrl}${extention}/employee/password/${employeeId}`;
export const getParticularEmployeeAccessLocationUrl = (employeeId) =>
  `${baseUrl}${extention}/employee/${employeeId}/access_location`;

// All customer endpoints
export const getCustomersTableListUrl = `${baseUrl}${extention}/customers`;
export const getParticularCustomerUrl = (customerId) =>
  `${baseUrl}${extention}/customer/${customerId}`;

//Reports layer endpoints
export const reportsPartners = `${baseUrl}${extention}/originator_report/partners`;
export const reportUrl = (urlType) => {
  return `${baseUrl}${extention}/originator_report/${urlType}`;
};
export const locationFiltersUrl = `${baseUrl}${extention}/get_location_filters`;

//User reportsurl end-points
export const userReportUrl = `${baseUrl}${extention}/reports`;
export const categoryAuditReportsUrl = `${baseUrl}${extention}/reports`;

// Organisation Status endpoint
export const organisationStatusUrl = `${baseUrl}${extention}/status`;

//Organisation All CSV file URL Endpoints
export const getPerticularFileUploadTabledataUrl = `${baseUrl}${extention}/upload/files`;
export const CSVDumpAllUrl = (urlType) => {
  return `${baseUrl}${extention}/${urlType}`;
};

//Organisation APK files URL Endpoints
export const getAPKTableDetailsUrl = (urlType) => {
  return `${baseUrl}${extention}/${urlType}`;
};

//Orgainsation manage
export const organisationUpdateLocationUrl = `${baseUrl}${extention}/admin/organisation/update_collection_dates`;
export const organisationUpdateVerificationUrl = `${baseUrl}${extention}/admin/organisation/update_verification_dates`;

//Organisation Settings endpoint
export const organisationSettingsUrl = `${baseUrl}${extention}/organisation/settings`;
export const organisationUpdateStateIDUrl = `${baseUrl}${extention}/state_id_types`;

//Bre API endpoints
export const getPerticularBreRule = `${baseUrl}${extention}/category`;
export const getPerticularBreRuleTable = (urlType) => {
  return `${baseUrl}${extention}/category/rules/${urlType}`;
};
export const getProductPerticularBreRuleTable = (urlType, id) => {
  return `${baseUrl}${extention}/category/rules/${urlType}/${id}`;
};
export const getProductBreRuleDetail = (urlType) => {
  return `${baseUrl}${extention}/category/${urlType}/products`;
};
export const getHardCodedRules = (urlType) => {
  return `${baseUrl}${extention}/category/hardcoded/rules/${urlType}`;
};
export const getHardCodedProductRules = (urlType, id) => {
  return `${baseUrl}${extention}/category/hardcoded/rules/${urlType}/${id}`;
};

export const deleteAndPutDataBreRuleData = (urlType, ruleId) => {
  return `${baseUrl}${extention}/category/${urlType}/rules/${ruleId}`;
};
export const deleteAndPutDataProductBreRuleData = (
  catogeryID,
  prodId,
  ruleId
) => {
  return `${baseUrl}${extention}/category/${catogeryID}/${prodId}/rules/${ruleId}`;
};

//profile score url
export const getPerticularProductsURL = (urlType) => {
  return `${baseUrl}${extention}/product/score/${urlType}`;
};
export const postScoreCreationRulesURL = (urlType) => {
  return `${baseUrl}${extention}/product/score/${urlType}`;
};
export const deletePerticularScoreRuleURL = (scoreCode, scoreId) => {
  return `${baseUrl}${extention}/product/score/${scoreCode}/${scoreId}`;
};

//Organisation products details URL Endpoints
export const getProductsDetails = `${baseUrl}${extention}/product`;
export const getProductsCategoryDetails = `${baseUrl}${extention}/category`;
export const getParticularProductDetail = (code) =>
  `${baseUrl}${extention}/product/${code}`;
export const getParticularProductCategoryDetail = (code) =>
  `${baseUrl}${extention}/category/${code}`;
export const updateParticularProductsActivationUrl = (id) =>
  `${baseUrl}${extention}/product/${id}/activate`;

//Organisation Update location API end points
export const zoneLocation = `${baseUrl}${extention}/zone_location`;
export const stateLocation = `${baseUrl}${extention}/state_location`;
export const areaLocation = `${baseUrl}${extention}/area_location`;
export const clusterLocation = `${baseUrl}${extention}/cluster_location`;
export const branchLocation = `${baseUrl}${extention}/branch_location`;
export const getLocationTableDetailsUrl = `${baseUrl}${extention}/get_location`;
export const unassignUrl = (urlType) => {
  return `${baseUrl}${extention}/${urlType}`;
};
export const profileScoreUrl = `${baseUrl}/api/profile_score/`;

//Update branch Url Endpoint
export const updateBranchUrl = (urlType) => {
  return `${baseUrl}${extention}/branch/${urlType}`;
};

//branch filter URL
export const getBranchDetailsListUrl = `${baseUrl}${extention}/branch_list_view`;
export const getStateCodeDetailsListUrl = `${baseUrl}${extention}/state_list_view`;

//Organisation Update District & Sub-District API end points
export const districtApiUrl = `${baseUrl}${extention}/district`;
export const subDistrictApiUrl = `${baseUrl}${extention}/sub_district`;
export const subDistrictTableUrl = `${baseUrl}${extention}/sub_district_list`;

//Application dalta layer
export const applicationCustomerGetUrl = (urlParams) => {
  return `${baseUrl}${extention}/application/loan/branch_list/${urlParams}`;
};

//Quality check
export const qualityCheckReadyGroupURL = `${baseUrl}${extention}/applications/qc_ready`;
export const updateQualityCheckReadyURL = (urlType) => {
  return `${baseUrl}${extention}/application/${urlType}/qc_verification`;
};
export const getDiscrepancyDropDownUrl = `${baseUrl}${extention}/get_discrepancy`;
export const raiseDiscrepancyRequestUrl = (urlType) => {
  return `${baseUrl}${extention}/qc_verification/${urlType}/discrepancy`;
};

//logdetails
export const getLogDetailsUrl = `${baseUrl}${extention}/track`;
export const ApplicationEMIStatusURL = `${baseUrl}${extention}/get_application_status`;

//External API's
export const getOrganisationExternalApiListUrl = `${baseUrl}${extention}/external_api_logs`;

//Active Loans list
export const getActiveLoansDetailsUrl = (customer_id) => {
  return `${baseUrl}${extention}/${customer_id}/active/loans`;
};

export const getApplicationDataUrl = (urlType) => {
  return `${baseUrl}${extention}/application/${urlType}`;
};
export const getCenterDataUrl = (urlType) => {
  return `${baseUrl}${extention}/center/${urlType}`;
};
export const getCenterDetailsURL = `${baseUrl}${extention}/centers`;
export const getStatusFilterData = `${baseUrl}${extention}/applications/status/type`;
export const getApplicationStatusListDetails = `${baseUrl}${extention}/get_application_status_list`;
//Reports page Api
export const dynamicLocationUrl = `${baseUrl}${extention}/get_dynamic_location`;
// Generate kit URL
export const generateKitUrl = `${baseUrl}${extention}/disbursement/kit_generation/applications`;

//Disbursement url
export const disbursementUrl = `${baseUrl}${extention}/disbursement_ready_groups`;

// kit generation list
export const esignReadyGroups = `${baseUrl}${extention}/esign_ready_groups`;

//disbursed groups list
export const disbursedGroupsList = `${baseUrl}${extention}/disbursed_groups`;

//Village creation URL endpoints
export const getVillageDetailsTableListUrl = `${baseUrl}${extention}/village_list`;
export const villageCreationUrl = `${baseUrl}${extention}/village`;
export const captainDetailsListUrl = `${baseUrl}${extention}/captain`;
export const assignVillageToCaptainUrl = `${baseUrl}${extention}/assign_captain`;
export const captainSurveyPendingVillageUrl = `${baseUrl}${extention}/survey_pending_village`;
export const surveyDoneVillageUrl = `${baseUrl}${extention}/survey_done_village`;
export const processedVillageUrl = `${baseUrl}${extention}/processed_village`;
export const reassignmentVillageDataURL = `${baseUrl}${extention}/villages/reassign-owner`;
export const villageSurveyDetailsCallUrl = `${baseUrl}${extention}/village_survey_details`;
export const getPerticularVillageDetailsUrl = (id) => {
  return `${baseUrl}${extention}/village/${id}`;
};
export const getVillageListFiltersListAPI = `${baseUrl}${extention}/get_village_filters`;

//bubble graph family
export const getBubbleGraphDetail = (employeeId) =>
  `${baseUrl}${extention}/family_tree/${employeeId}`;

//Ek Platform application Url
export const getApplicationsTableDetails = `${baseUrl}${extention}/applications`;

//Group detais Url
export const getGroupListURL = `${baseUrl}${extention}/groups`;
export const getGroupDetailsURL = `${baseUrl}${extention}/groups`;
export const getGroupDetailsByCenterURL = `${baseUrl}${extention}/group_by_center`;
export const getGroup_statusURL = `${baseUrl}${extention}/group_status`;
export const changeGroupStatusForGroupUrl = `${baseUrl}${extention}/update_group_status`;

//Dirsbursment groups
export const disbursmentURL = `${baseUrl}${extention}/disbursement`;
export const repaymentDates = `${baseUrl}${extention}/disbursement/repayment_dates`;

//Collection URLs
export const getVerificationCollectionDatesUrl = `${baseUrl}${extention}/allowed_verification_dates`;
export const getVerificationCollectionDetailsUrl = `${baseUrl}${extention}/collections/list/maker`;
export const getClosingCollectionDetailsUrl = `${baseUrl}${extention}/collections/list/closing`;
export const checkerCollectionDatesUrl = `${baseUrl}${extention}/collections/list/checker`;

//Collection closing drill down API's URL
export const checkerCollectionBranchUrl = (collection_code) => {
  return `${baseUrl}${extention}/collections/branches/${collection_code}/checker`;
};
export const checkerCollectionCollectorUrl = (
  collection_code,
  collector_code
) => {
  return `${baseUrl}${extention}/collections/list/collector/${collection_code}/${collector_code}`;
};
export const checkerCollectionGroupListUrl = (
  collection_code,
  collector_code,
  center_code
) => {
  return `${baseUrl}${extention}/collections/list/groups/${collection_code}/${collector_code}/${center_code}`;
};
export const checkerCollectionIndividualListUrl = (
  collection_code,
  center_code
) => {
  return `${baseUrl}${extention}/collections/list/centers/individual/${collection_code}/${center_code}`;
};
export const checkerCollectionCentersListGroupUrl = (
  collection_code,
  center_code,
  group_id
) => {
  return `${baseUrl}${extention}/collections/list/centers/${collection_code}/${center_code}/${group_id}`;
};
export const checkerCollectionCentersListIndividualUrl = (
  collection_code,
  center_code
) => {
  return `${baseUrl}${extention}/collections/list/centers/individual/${collection_code}/${center_code}`;
};

//===================
export const getCollectionBranchUrl = (branch_code) => {
  return `${baseUrl}${extention}/collections/branches/${branch_code}/maker`;
};
export const getCollectionCollectorUrl = (collector_code) => {
  return `${baseUrl}${extention}/collections/list/collector/${collector_code}`;
};
export const getCollectionListGroupUrl = (collector_code, center_code) => {
  return `${baseUrl}${extention}/collections/list/groups/${collector_code}/${center_code}`;
};
export const collectionCustomerGroupUrl = (center_code_url, group_id_url) => {
  return `${baseUrl}${extention}/collections/list/centers/${center_code_url}/${group_id_url}`;
};
export const collectionCustomerIndividualUrl = (
  collector_code,
  center_code
) => {
  return `${baseUrl}${extention}/collections/list/groups/${collector_code}/${center_code}`;
};
export const collectionClosingListUrl = `${baseUrl}${extention}/collections/list/closing`;

//Bank Creation
export const getBcCreationTableUrl = `${baseUrl}${extention}/bc`;
export const getAllBCListDetailsUrl = `${baseUrl}${extention}/bc_list`;
export const getAllBranchesByBCUrl = `${baseUrl}${extention}/branches_by_bc`;
export const getAllGroupByBranchUrl = `${baseUrl}${extention}/group_by_branch`;

//E-Sign api URL
export const getEsignParametersURL = (app_id) => {
  return `${baseUrl}/web/api/organisation/application/doc_e_sign_emsigner/${app_id}`;
};
export const getAllBreResultsURL = (id) => {
  return `${baseUrl}${extention}/bre_track/${id}`;
};

//QC for fail reasons
export const getAmlFailCustomerUrl = `${baseUrl}${extention}/aml_fail_customers`;
export const AMLFailesQCUrl = (id) => {
  return `${baseUrl}${extention}/aml_fail_action/${id}`;
};
export const ApplicationQcTableUrl = `${baseUrl}${extention}/application_qc`;
export const ApplicationFailesQCUrl = (id) => {
  return `${baseUrl}${extention}/application_qc_action/${id}`;
};

//rejection URLS
export const getRejectedApplicationsUrl = `${baseUrl}${extention}/rejected_applications`;
export const getRejectedCustomersUrl = `${baseUrl}${extention}/rejected_customers`;

//audit trail
export const getAuditTrailUrl = `${baseUrl}${extention}/audit_trail`;

//post Recb check Url
export const postReCbcheckUrl = `${baseUrl}${extention}/recb/check`;

//CBS pending Url
export const getCbsPendingCustomersDataUrl = `${baseUrl}${extention}/cbs_pending_customers`;
export const getCbsPendingCentersDataUrl = `${baseUrl}${extention}/cbs_pending_centers`;
export const getCbsPendingGroupsDataUrl = `${baseUrl}${extention}/cbs_pending_groups`;
export const getCbsPendingApplicationsDataUrl = `${baseUrl}${extention}/cbs_pending_applications`;
export const getCbsPendingDisbursementDataUrl = `${baseUrl}${extention}/cbs_pending_disbursement`;
export const getCbsPendingCollectionDataUrl = `${baseUrl}${extention}/cbs_pending_collection`;

//CBS restart lainks
export const reStartCbsCentetr = `${baseUrl}${extention}/cbs_retry_center/`;
export const reStartCbsGroup = `${baseUrl}${extention}/cbs_retry_group/`;
export const reStartCbsCustomer = `${baseUrl}${extention}/cbs_retry_customer/`;
export const reStartCbsApplication = `${baseUrl}${extention}/cbs_retry_application/`;
export const reStartCbsDisbursement = `${baseUrl}${extention}/cbs_retry_disbursement/`;
export const reStartCbsCollection = `${baseUrl}${extention}/cbs_retry_collection/`;
//getNotificationDataUrl

//DMS pending Url
export const getDMSPendingApplicationsDataUrl = `${baseUrl}${extention}/dms_pending_applications`;
export const reStartDMSApplication = `${baseUrl}${extention}/dms_retry_upload/`;

// S3 Request & Response Url
export const postS3requestResponseUrl = `${baseUrl}${extention}/get_presigned_url`;

//Preclose Url
export const precloseInitateAppsUrl = `${baseUrl}${extention}/preclosure/applications/list`;
export const preCloseMakerUrl = `${baseUrl}${extention}/pre_closure_maker`;
export const preCloseCheckerUrl = `${baseUrl}${extention}/pre_closure_checker`;
export const preCloseInitateUrl = `${baseUrl}${extention}/pre_closure_initiate`;

//Death Mark Dates Url
export const getDeathMarkDataUrl = `${baseUrl}${extention}/death_mark/initiate/list/applications`;
//getNotificationDataUrl
export const getNotificationDataUrl = `${baseUrl}${extention}/send/employee/custom/notification`;
export const getLatestBellNotificationDataUrl = `${baseUrl}${extention}/custom/notification/list`;
export const postReadNotificationNotificationUrl = (id) => {
  return `${baseUrl}${extention}/custom/notification/${id}`;
};
export const teleVerificationUrl = `${baseUrl}${extention}/tele_verification_questions`;
export const particularQuestionDetail = (code) =>
  `${baseUrl}${extention}/tele_verification_questions/${code}`;
export const teleVerificationListUrl = `${baseUrl}${extention}/tele_verification_list`;
export const televerificationQCUrl = (id) => {
  return `${baseUrl}${extention}/tele_verification_action/${id}`;
};
export const televerificationDetailsQCUrl = (id) => {
  return `${baseUrl}${extention}/tele_verification_details/${id}`;
};
//Loan Card Details page changes
export const getLoanCardDetailsApiUrl = (id) => {
  return `${baseUrl}${extention}/loan/details/${id}`;
};
//E-Stamp api URL
export const getEstampURL = (app_id) => {
  return `${baseUrl}/web/api/organisation/application/doc_e_stamp/${app_id}`;
};

//E-Sign manual docs upload
export const getEsignManualDocsURL = (app_id) => {
  return `${baseUrl}/web/api/organisation/application/${app_id}/upload_docs`;
};

//Update DMS Status
export const getUpdateDMSStatus = (app_id) => {
  return `${baseUrl}${extention}/status/dms/${app_id}`;
};

//User Activity URL
export const getUserActivityData = `${baseUrl}${extention}/user_audit_trail`;

//Get eligible groups for group transfer at appliacation level
export const eligibleGroupsUrl = (id) => {
  return `${baseUrl}${extention}/application/group_transfer/${id}`;
};

//Get eligible village for group transfer at appliacation level
export const eligibleVillageUrl = (id) => {
  return `${baseUrl}${extention}/center_transfer_from_village/${id}`;
};

//Get eligible center for group transfer at appliacation level
export const eligibleCenterUrl = (id) => {
  return `${baseUrl}${extention}/group_transfer_from_center/${id}`;
};

export const getBCBreRuleTable = (urlType) => {
  return `${baseUrl}${extention}/bc/${urlType}/rules`;
};

export const deleteAndPutDataBCBreRuleData = (urlType, ruleId) => {
  return `${baseUrl}${extention}/bc/${urlType}/rules/${ruleId}`;
};
//Get Loan Statement
export const getLoanStatementURL = (id) => {
  return `${baseUrl}${extention}/get_account_statement/${id}`;
};
