import CryptoJS from "crypto-js";

// Generate a random secret key
const generateSecretKey = () => {
  return CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Base64);
};

// Use the generated secret key
const secretKey = generateSecretKey();

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (encryptedData) => {
  try {
    if (
      encryptedData === null ||
      encryptedData === undefined ||
      encryptedData.length === 0 ||
      Array.isArray(encryptedData)
    ) {
      return encryptedData;
    }

    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Error decrypting data:", error);
    return encryptedData; // or handle the error in an appropriate way
  }
};
