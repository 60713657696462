export const login = (
  state = { userRole: sessionStorage.getItem("userRole") || "" },
  action
) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      sessionStorage.setItem("userRole", action.payload.userRole);
      return {
        ...state,
        values: action.payload,
        userRole: action.payload.userRole,
      };
    }
    default: {
      return state;
    }
  }
};
