import {
  FETCH_USER_FEATURES_REQUEST,
  FETCH_USER_FEATURES_SUCCESS,
  FETCH_USER_FEATURES_FAILURE,
} from "./actions/userFeaturesActions";

const initialState = {
  data: [],
  userData: null, // Initialize userData as null
  loading: false,
  error: null,
};

const userFeaturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_FEATURES_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_USER_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        userData: action.userData, // Set the userData from the action payload
        error: null,
      };

    case FETCH_USER_FEATURES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default userFeaturesReducer;
