import {
  getLatestBellNotificationDataUrl,
  getNotificationDataUrl,
  postReadNotificationNotificationUrl,
} from "../../urlConfiguration";
import { getAPICall, postAPICall, putAPICall } from "../axiosMethodCall";

export const getNotificationData = async () => {
  try {
    const response = await getAPICall(getNotificationDataUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getNotificationDataWithParams = async (payload) => {
  let urlWithQueryParams = "";
  if (payload.state_code.length !== 0 || payload.branch_code.length !== 0) {
    const params1 = new URLSearchParams();
    payload.state_code.forEach((code) => {
      params1.append("state_code", code);
    });
    const params2 = new URLSearchParams();
    payload.branch_code.forEach((code) => {
      params2.append("branch_code", code);
    });
    urlWithQueryParams = `${getNotificationDataUrl}?${params1.toString()}&${params2.toString()}`;
  } else urlWithQueryParams = getNotificationDataUrl;
  try {
    const response = await getAPICall(urlWithQueryParams);
    return response;
  } catch (error) {
    return error;
  }
};

export const postNotificationData = async (payload) => {
  try {
    const response = await postAPICall(getNotificationDataUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getLatestBellNotificationData = async () => {
  try {
    const response = await getAPICall(getLatestBellNotificationDataUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const postReadNotificationNotification = async (id, payload) => {
  try {
    const url = postReadNotificationNotificationUrl(id);
    const response = await putAPICall(url, payload);
    return response;
  } catch (error) {
    return error;
  }
};
