import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Bell } from "react-feather";
import {
  Badge,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  getLatestBellNotificationData,
  postReadNotificationNotification,
} from "../../../components/dataLayer/Notification";

const NotificationDropdown = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationCounter, setNotificationCounter] = useState(0);
  const [openNotificationId, setOpenNotificationId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    getLatestBellNotification();
  }, []);

  const getLatestBellNotification = async () => {
    const response = await getLatestBellNotificationData();
    if (response?.status === 200) {
      const formattedData = response.data.results.map((item) => ({
        ...item,
        notification_content: item?.notification_content
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          )),
      }));
      setNotifications(formattedData);
      const unreadCount = formattedData.filter(
        (item) => !item.is_notification_read
      ).length;
      setNotificationCounter(unreadCount);
    } else {
      setNotifications([]);
      setNotificationCounter(0);
    }
  };

  const toggleMessage = async (e, notification_id, type) => {
    e.preventDefault();
    setOpenNotificationId((prevId) =>
      prevId === notification_id ? null : notification_id
    );
    const payload = {
      is_notification_read: type,
    };
    await postReadNotificationNotification(notification_id, payload);
    getLatestBellNotification();
  };

  function getInitials(notification_title) {
    // Trim any leading/trailing whitespace and split the string by spaces
    let words = notification_title.trim().split(/\s+/);

    // Initialize an array to hold initials
    let initials = [];

    // Loop through the words and get the first letter of each word
    for (let i = 0; i < words.length && initials.length < 2; i++) {
      if (words[i].length > 0) {
        // Check if the word is not an empty string
        initials.push(words[i][0].toUpperCase());
      }
    }

    // Join the initials and return the result
    return initials.join("");
  }

  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component="li"
        className="media-list scrollable-container"
        options={{
          wheelPropagation: false,
        }}
      >
        {notifications.map((item, index) => (
          <div key={index} className="" style={{ position: "relative" }}>
            <div
              className="nav-link dropdown-user-link"
              style={{
                width: "100%",
                // margin: "0px 7px",
                padding: "6px 7px",
                display: "flex",
                borderBottom: "2px solid transparent",
                borderImage:
                  "linear-gradient(118deg, #1b634d, rgba(27, 99, 77, 0.7)) 1 / 1 / 0 stretch",
                borderImageSlice: "1",
                background: `${
                  item?.is_notification_read ? "" : "rgb(219 219 219)"
                }`,
              }}
              onClick={(e) => toggleMessage(e, item.notification_id, true)}
            >
              {/* <Avatar /> */}
              <div className="me-1">
                <Avatar
                  {...(item.img
                    ? { img: item.img, imgHeight: 32, imgWidth: 32 }
                    : item.avatarContent
                    ? {
                        content: item.avatarContent,
                        color: item.color,
                      }
                    : item.avatarIcon
                    ? {
                        icon: item.avatarIcon,
                        color: item.color,
                      }
                    : null)}
                />
              </div>
              <div className="user-nav d-block" style={{ marginLeft: "5px" }}>
                <div
                  className="user-name fw-bold"
                  style={{ fontWeight: "bold" }}
                >
                  {item.notification_title}
                </div>
                <small className="user-name fw-bold">{item.created_at}</small>
                {item.notification_id === openNotificationId && (
                  <div className="user-status mt-1">
                    {item.notification_content}
                  </div>
                )}
                {item.notification_id === openNotificationId &&
                  item?.is_notification_read && (
                    <div
                      className="resetNotification"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleMessage(e, item.notification_id, false);
                      }}
                      style={{
                        // position: "absolute",
                        // bottom: "8px",
                        // right: "10px",
                        cursor: "pointer",
                        color: "#ff000082",
                      }}
                    >
                      Unread
                    </div>
                  )}
              </div>
            </div>
          </div>
        ))}
      </PerfectScrollbar>
    );
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (dropdownOpen === false) setOpenNotificationId(null);
  };

  return (
    <UncontrolledDropdown
      tag="li"
      className="dropdown-user nav-item"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
        href="/"
        onClick={(e) => e.preventDefault()}
      >
        {notifications.length !== 0 ? (
          <div>
            <Bell size={21} />
            {notificationCounter ? (
              <Badge
                pill
                color="danger"
                style={{ position: "relative", top: "-11px", right: "9px" }}
              >
                {notificationCounter}
              </Badge>
            ) : null}
          </div>
        ) : null}
      </DropdownToggle>
      <DropdownMenu right tag="ul" className="dropdown-menu-media mt-0">
        {renderNotificationItems()}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default NotificationDropdown;
