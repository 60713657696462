import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { Base64 } from "js-base64"; // Import Base64 from js-base64
import { decrypt, encrypt } from "../AES_GCM";
const cookies = new Cookies();

const ENABLE_AES_ENCRYPTION =
  process.env.REACT_APP_ENABLE_AES_ENCRYPTION === "true";

const getHeaders = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  "instance-token": cookies.get("user-token"),
});

const handleErrorData = async (error) => {
  if (error.response && error.response.data) {
    const decodedData = ENABLE_AES_ENCRYPTION
      ? await decrypt(error.response.data)
      : Base64.decode(error.response.data); // Use Base64 if encryption is disabled
    error.response.data = JSON.parse(decodedData);
  }
  return error;
};

function handleAPIError(error) {
  if (
    error.response &&
    (error.response.status === 403 || error.response.status === 401)
  ) {
    cookies.remove("user-token");
    window.location.href = "/";
  } else if (error.response && error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (error.response && error.response.status === 422) {
    const binaryMessage = error?.response?.data;
    const decodedMessage = JSON.parse(atob(binaryMessage));
    toast.error(decodedMessage?.message);
  } else if (error.response && error.response.status === 400) {
    const binaryMessage = error?.response?.data;
    const decodedMessage = JSON.parse(atob(binaryMessage));
    toast.error(decodedMessage?.errors);
  } else if (error.response && error.response.status === 404) {
    const binaryMessage = error?.response?.data;
    const decodedMessage = JSON.parse(atob(binaryMessage));
    toast.error(decodedMessage?.errors);
  } else if (error.response && error.response.status === 409) {
    const binaryMessage = error?.response?.data;
    const decodedMessage = JSON.parse(atob(binaryMessage));
    toast.error(decodedMessage?.message);
  }
  return Promise.reject(handleErrorData(error));
}

const handleResponse = async (response) => {
  if (response.data) {
    const decodedData = ENABLE_AES_ENCRYPTION
      ? await decrypt(response.data)
      : Base64.decode(response.data); // Use Base64 if encryption is disabled
    response.data = JSON.parse(decodedData);
  }
  return response;
};

export const getAPICall = (url, params) =>
  axios
    .get(url, { headers: getHeaders(), params })
    .then(handleResponse)
    .catch((error) => handleAPIError(error));

export const postAPICall = async (url, data, params) => {
  const encodedData = ENABLE_AES_ENCRYPTION
    ? await encrypt(JSON.stringify(data))
    : Base64.encode(JSON.stringify(data)); // Use Base64 if encryption is disabled
  try {
    const response = await axios.post(url, encodedData, {
      headers: getHeaders(),
      params,
    });
    return handleResponse(response);
  } catch (error) {
    return await handleAPIError(error);
  }
};

export const putAPICall = async (url, data, params) => {
  const encodedData = ENABLE_AES_ENCRYPTION
    ? await encrypt(JSON.stringify(data))
    : Base64.encode(JSON.stringify(data)); // Use Base64 if encryption is disabled
  try {
    const response = await axios.put(url, encodedData, {
      headers: getHeaders(),
      params,
    });
    return handleResponse(response);
  } catch (error) {
    return await handleAPIError(error);
  }
};

export const deleteAPICall = async (url, data, params) => {
  const encodedData = data
    ? ENABLE_AES_ENCRYPTION
      ? await encrypt(JSON.stringify(data))
      : Base64.encode(JSON.stringify(data))
    : null; // Use Base64 if encryption is disabled
  try {
    const response = await axios.delete(url, {
      headers: getHeaders(),
      params: params,
      data: encodedData,
    });
    return handleResponse(response);
  } catch (error) {
    return await handleAPIError(error);
  }
};
